@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070713;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #737373;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #555555;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-effect {
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 0 auto;
  animation: zoomInOut 1s infinite;
}

.zoom-effect img {
  height: auto;
  display: block;
}

@media screen and (max-width: 768px) {
  .zoom-effect {
    width: 100px;
    height: 100px;
  }
}
